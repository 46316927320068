import * as React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Seo from "../components/SEO"
import Button from "../components/Button/Button"
import { BlogSingleStyles } from "../components/Info/InfosStyles"


const cookies = () => {
  return (
    <>
      <Seo title="cookies" />
      <Layout>
         <BlogSingleStyles>
            <h1 className="blogsingle__title">Какво представляват „бисквитките“ ?</h1>     
            <article className="blogsingle__content">            
                <p> &quot;Бисквитките&quot; са малки текстови файлове, които се съхраняват в  браузъра или в паметта на компютър и/или мобилно устройство,  когато посещавате даден уебсайт.</p>
                <p>Целта на „бисквитките“ е да направят преживяването Ви в нашия онлайн каталог персонализирано. Те запомнят Вашите действия и предпочитания за определен период от време, което служи, при повторни посещения на страницата ни или при преминането от една страница към друга. Някои „бисквитки“ събират аналитична информация за поведението на потребителите.</p>
                <p>Има различни видове &quot;бисквитки&quot;. Te могат да се съхраняват за различни периоди от време в браузъра  или устройството Ви. </p>
                <h3 id="-">Какви „бисквитки“ използваме ?</h3>
                <p> Ние използваме <strong><em>бисквитки за анализ и статистика</em></strong>. Тези „бисквитки“ ни помагат да анализираме използването на нашата уеб страница и да  проследяваме ефективността на услугата и комуникацията й. Използваме  <strong><em>Google Analytics</em></strong>, за да оценим как потребителите взаимодействат със страницата. По този начин инструмента за анализ ни позволява да определим дали сте разглеждали конкретна страница, колко време сте прекарали в уебсайта или пък дали сте отворили изпратен от нас имейл. Така можем да Ви предоставим само тази информация, която Ви интересува. Повече информация и настройки можете да видите <a href="https://support.google.com/analytics/answer/6004245?hl=bg">тук</a></p>
                <h3 id="-">Как да деактивирате използването на „бисквитки“ ?</h3>
                <p> Активирането и деактивирането на „бисквитки“ може да се управлява от настройките на използвания от Вас браузър. Като потребител Вие можете да зададете своите предпочитания за всеки един от браузърите  и/или устройствата, които използвате за достъп до Интернет.</p>
                <p>Искате да научите повече за &quot;бисквитките&quot;? Посетете <a href="https://www.aboutcookies.org">www.aboutcookies.org</a>.</p>
              <div className="blogsingle__back">
                <Button text="Към начална страница" as={Link} to="/" />
              </div>
            </article>
          </BlogSingleStyles>
      </Layout>
    </>
  )
}

export default cookies
